import { MatStartDate } from '@angular/material/datepicker';

/**
 * Returns in format "YYYY-MM-DD".
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}  {string}
 */
export function getDateAsString(date: Date | string): string {
  if (date == null) return null;
  const regexp: RegExp = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  if (regexp.test(date.toString())) {
    return date as string;
  }

  date = new Date(date);
  const tzoffset: number = (date as Date).getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime: string = new Date((date as Date).getTime() - tzoffset).toISOString().slice(0, -1);
  return localISOTime.substring(0, 10);
}

/**
 * Returns string or date object as date object.
 *
 * @export
 * @param {(Date | string)} date The object to be returned as date object.
 * @return {*}  {Date}
 */
export function getAsDate(date: Date | string): Date {
  if (date == null) return null;
  return new Date(date);
}

/**
 * Returns date on another week.
 *
 * @export
 * @param {(Date | string)} date The object to be returned as date on another week
 * @return {*}  {Date}
 */
export function getDateOnAnotherWeek(date: Date | string, weekDifference: number): Date {
  if (date == null) return null;
  var dateToUse: Date = new Date(date);
  var dateOnAnotherWeek: Date = new Date(dateToUse.getFullYear(), dateToUse.getMonth(), dateToUse.getDate() + (7 * weekDifference));
  return dateOnAnotherWeek;
}

/**
 * Returns the monday of given day's week.
 *
 * @export
 * @param {(Date | string)} date The date.
 * @return {*}  {Date}
 */
export function getMondayOfDate(date: Date | string): Date {
  if (date == null) return null;
  const dateToUse: Date = new Date(date);

  const day: number = dateToUse.getDay(),
    diff = dateToUse.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(dateToUse.setDate(diff));
}

export function getFridayOfDate(date: Date | string): Date {
  if (date == null) return null;

  const dateToUse: Date = new Date(date);
  const day: number = dateToUse.getDay();
  const diff: number = dateToUse.getDate() - day + (day == 0 ? -6 : 1);

  return new Date(dateToUse.setDate(diff + 4));
}

export function getSundayOfDate(date: Date | string): Date {
  if (date == null) return null;

  const dateToUse: Date = new Date(date);
  const day: number = dateToUse.getDay();
  // const first = dateToUse.getDate() - (day == 0 ? 6 : day + 1);
  const diff: number = dateToUse.getDate() - day + (day == 0 ? -6 : 1);

  return new Date(dateToUse.setDate(diff + 6));
}

/**
 * Returns dates starting from dateStart (including) until dateEnd (including).
 *
 * @export
 * @param {(Date | string)} dateStart The first date to start.
 * @param {(Date | string)} dateEnd The last date to start.
 * @return {*}  {Date[]}
 */
export function getDatesBetween(dateStart: Date | string, dateEnd: Date | string): Date[] {

  const datesToReturn: Date[] = [];

  const dateStartDate: Date = new Date(dateStart);
  const dateEndDate: Date = new Date(dateEnd);

  datesToReturn.push(dateStartDate);
  let currentDate: Date = new Date(dateStartDate);
  for (let index = 0; index < 100; index++) {
    currentDate = new Date(new Date(currentDate).setDate(currentDate.getDate() + 1));

    if (isDateGreater(currentDate, dateEndDate)) {
      break;
    }

    datesToReturn.push(currentDate);

  }

  return datesToReturn;
}

export function isDateLessThanGivenSecondsOld(date: Date | string, seconds: number): boolean {
  const dateToCheck: Date = new Date(date);
  return new Date().getTime() - dateToCheck.getTime() < seconds * 1000;
}

/**
 * Return the number or date in week.
 * 0 = Sunday
 * ...
 * 6 = Sunday
 *
 * @export
 * @param {Date} date
 * @return {*}  {number}
 */
export function getWeekDayNumber(date: Date): number {
  return date.getDay();
}

/**
 * Is the first date greater than the second
 *
 * @export
 * @param {(Date | string)} date1
 * @param {(Date | string)} date2
 */
export function isDateGreater(date1: Date | string, date2: Date | string): boolean {
  const date1String: string = getDateAsString(date1);
  const date2String: string = getDateAsString(date2);
  if (date1String === date2String) return false; // Same date
  return new Date(date1String).getTime() > new Date(date2String).getTime();
}

/*
* Is the first date greater than the second or are dates the same.
*
* @export
* @param {(Date | string)} date1
* @param {(Date | string)} date2
*/
export function isDateGreaterOrSame(date1: Date | string, date2: Date | string): boolean {
  const date1String: string = getDateAsString(date1);
  const date2String: string = getDateAsString(date2);
  if (date1String === date2String) { return true; } // Same date
  return new Date(date1String).getTime() > new Date(date2String).getTime();
}

/**
 * Is the first date less than the second
 *
 * @export
 * @param {(Date | string)} date1
 * @param {(Date | string)} date2
 */
export function isDateLess(date1: Date | string, date2: Date | string): boolean {
  const date1String: string = getDateAsString(date1);
  const date2String: string = getDateAsString(date2);
  if (date1String === date2String) return false; // Same date
  return new Date(date2String).getTime() > new Date(date1String).getTime();
}

/**
 * Is the first date less than the second or are the dates same.
 *
 * @export
 * @param {(Date | string)} date1
 * @param {(Date | string)} date2
 */
export function isDateLessOrSame(date1: Date | string, date2: Date | string): boolean {
  const date1String: string = getDateAsString(date1);
  const date2String: string = getDateAsString(date2);
  if (date1String === date2String) { return true; } // Same date
  return new Date(date2String).getTime() > new Date(date1String).getTime();
}